import {  Navigate } from "react-router-dom";
import { useAuth } from "../../shared/hooks/UseAuth";
import { AUTH } from "../consts";
import { Sidebar } from "../../features/Sidebar";
import Topbar from "../../features/TopBar/Topbar";
import { useState } from "react";


const ReaquiredAuth = ({children})=>{
    const {isAuth} = useAuth();
    const [full,setFull] = useState(false)
    if(!isAuth) {
        return <Navigate to={AUTH} />
    }
    return (
        <div>
            <Topbar full={full} setFull={setFull}/>
            <div className="d-flex">
                <Sidebar full={full} setFull={setFull}/>
                <div style={{marginTop:"70px",overflow:"hidden", width:"100%", fontFamily:"roboto"}}>{children}</div>
            </div>
        </div>
    )
    }
export default ReaquiredAuth;