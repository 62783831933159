import AppRouter from '../Router/AppRouter';
import { AuthProvider } from '../Router/hox/AuthProvider';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NotificationContext } from './NotificationContext';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function App() {
  const showNotification = (type, heading='', message='' )=>{
    switch(type){
        case 'error':
            return NotificationManager.error(heading,message, 5000);
        case 'success':
          return  NotificationManager.success(heading,message, 5000 );
        default : return;
        case 'warning':
            return NotificationManager.warning(heading,message, 5000);
        case 'info':
            return NotificationManager.info(heading,message, 5000);
    }
  }
  return (
    <AuthProvider>
      <NotificationContext.Provider value={showNotification}>
        <div className="App">
          <div className="blue-fon-modal" />
          <NotificationContainer />
          <AppRouter />
        </div>
      </NotificationContext.Provider>
    </AuthProvider>
  );
}

export default App;
